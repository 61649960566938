import { useFormik } from "formik"; 
import { Button, Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { KTIcon } from "../../_metronic/helpers";
import AmountInput from "../../_metronic/layout/components/common/AmountInput";
import { ISquezzMeInfoDetails } from "../../constants/types";
import { useState } from "react";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import Form from 'react-bootstrap/Form';
import { updateRequestStatusForAll } from "../../api";
import { format, parseISO } from 'date-fns'; // Importing date-fns functions
// Import Material UI components
import { TextField, Switch, FormControlLabel } from "@mui/material";

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean, catId: any) => void;
  details: ISquezzMeInfoDetails;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const ApproveSquezzMe = ({ show, handleClose, details }: Props) => {
  const { mutateAsync: updateRequestStatusForAllAPI } = useMutation(
    "update-status-all",
    updateRequestStatusForAll,
  );
  const [showTimeRange, setShowTimeRange] = useState(false);

  // Format the slotDate and startTime
  const formattedDate = details?.slotDate ? format(parseISO(details.slotDate), "MMM dd, yyyy") : "";
  const formattedTime = details?.startTime || "";

  const validationSchema = Yup.object().shape({
    price: Yup.number()
      .required("Price is required and should not be blank")
      .min(1, "Price should be greater than zero"),
  });
// Convert a time string from 24-hour format to 12-hour format with AM/PM
const formatTimeToAMPM = (timeString: string) => {
  const [hours, minutes] = timeString.split(":");
  let ampm = "AM";
  let hoursNum = parseInt(hours, 10);

  if (hoursNum >= 12) {
    ampm = "PM";
    if (hoursNum > 12) {
      hoursNum -= 12;
    }
  } else if (hoursNum === 0) {
    hoursNum = 12;
  }

  return `${hoursNum}:${minutes} ${ampm}`;
};

// Convert time in AM/PM format to 24-hour format
  const formatTimeToHHMM = (timeString: any) => {
    const [time, modifier] = timeString.split(" ");
    let [hours, minutes] = time.split(":");

    if (modifier === "PM" && hours !== "12") {
      hours = String(parseInt(hours, 10) + 12);
    }

    if (modifier === "AM" && hours === "12") {
      hours = "00";
    }

    return `${hours}:${minutes}`;
  };

  const formik = useFormik({
    initialValues: {
      price: "",
      minimumSpend: "",
      description: "",
      requestTimeRange: false, // Switch state
      date: details?.slotDate ? format(parseISO(details.slotDate), "yyyy-MM-dd") : "", // Format date for input
      time: details?.startTime ? formatTimeToHHMM(details.startTime) : "", // Set initial time
      returnTime: details?.returnTime ? formatTimeToHHMM(details.returnTime) : "", // Set initial time
    },
    validationSchema,
    onSubmit: async (values) => {
      // Time comparison validation
      const startTime = formatTimeToHHMM(values.time);
      const returnTime = formatTimeToHHMM(values.returnTime);
      // if (values.requestTimeRange && !returnTime) {
      //   snackActions.error("Please select return time.");
      //   return;
      // }
      if (values.requestTimeRange && (startTime > returnTime)) {
        snackActions.error("Return time must be after start time");
        return;
      }

      let bodyParams: any = {
        categoryId: details?.catId, 
        id: details?._id ? details?._id : undefined,  
        status: 1,  
        finalPrice: values.price,
        minimumSpend: values.minimumSpend, 
        reasonText: values.description,  
        adminDesiredDate: values.requestTimeRange ? values.date : "", 
        adminDesiredTime: values.requestTimeRange ? formatTimeToAMPM(values.time) : "",
        returnTime: values.requestTimeRange && values.returnTime ? formatTimeToAMPM(values.returnTime) : "",
        IsMinimumSpend: values.requestTimeRange ?? false
      };
      let response: any = null;

      response = await updateRequestStatusForAllAPI(bodyParams);
      if (response?.status) {
        snackActions.success(response?.message ?? "Status Updated Successfully!");
        formik.resetForm();
        handleClose(true, details?.catId);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-600px"
      show={show}
      onHide={() => handleClose(false, details?.catId)}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>Approve Request</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false, details?.catId)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="pt-6 d-flex align-items-center">
            <label className="form-label fw-bold">
              This request was created for {details?.person} people on {formattedDate} at {formattedTime}. Would you like to update it?
            </label>
            <FormControlLabel
              style={{ marginLeft: "10px" }}
              control={
                <Switch
                  checked={formik.values.requestTimeRange}
                  onChange={(e) => {
                    formik.setFieldValue("requestTimeRange", e.target.checked);
                    setShowTimeRange(e.target.checked);
                  }}
                />
              }
              label=""
            />
          </div>

          {/* Conditional Time Range Inputs */}
          {showTimeRange && (
            <div className="pt-6">
              <div className="d-flex">
                <Form.Group className="me-3">
                  <Form.Label><label className="form-label fw-bold"> Approved Date</label></Form.Label>
                  <TextField
                    type="date"
                    {...formik.getFieldProps("date")}
                    fullWidth
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label><label className="form-label fw-bold"> Approved Time</label></Form.Label>
                  <TextField
                    type="time"
                    {...formik.getFieldProps("time")}
                    fullWidth
                  />
                </Form.Group>
                <Form.Group style={{ marginLeft: "1rem" }}>
                  <Form.Label><label className="form-label fw-bold"> Return Time (optional)</label></Form.Label>
                  <TextField
                  
                    type="time"
                    {...formik.getFieldProps("returnTime")}
                    fullWidth
                  />
                </Form.Group>
              </div>
              {formik.touched.date && formik.errors.date && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.date}</div>
                </div>
              )}
              {formik.touched.time && formik.errors.time && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.time}</div>
                </div>
              )}
            </div>
          )}

          <div className="pt-6">
            <label className="form-label fw-bold"> Approved squeez per person</label>
            <AmountInput
              placeholder="Price"
              formikProps={{ ...formik.getFieldProps("price") }}
              handleChange={(e) => formik.handleChange(e)}
            />
            {formik.touched.price && formik.errors.price && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.price}</div>
              </div>
            )}
          </div>

          <div className="pt-6">
            <label className="form-label fw-bold"> Minimum spend (optional)</label>
            <AmountInput
              placeholder="Minimum spend"
              formikProps={{ ...formik.getFieldProps("minimumSpend") }}
              handleChange={(e) => formik.handleChange(e)}
            />
          </div>
          <div className="pt-6">
            <label className="form-label fw-bold">Description</label>
            <Form>
              <Form.Group className="mb-3">
                <Form.Control
                  as="textarea"
                  rows={3}
                  {...formik.getFieldProps("description")}
                  onChange={(e) => formik.handleChange(e)}
                />
              </Form.Group>
            </Form>
            {formik.touched.description && formik.errors.description && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.description}</div>
              </div>
            )}
          </div>
          <div className="w-100 d-flex p-5 align-items-center">
            <Button
              onClick={() => {
                formik.resetForm();
                handleClose(false, details?.catId);
              }}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            <Button type="submit" size="lg" variant="primary">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot,
  );
};

export default ApproveSquezzMe;
