import { useFormik } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import { KTIcon } from "../../_metronic/helpers";
import { addRestaurantProvider, getAllPaymentProviderInfoBasedOnCategory, getPaymentProvider, getRestaurantInfo } from "../../api";
import { useMutation } from "react-query";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import AddIcon from '@mui/icons-material/Add';
interface PaymentProvider {
  _id: string;
  name: string;
  IsDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface PaymentProviderField {
  provider: string;
  merchantID?: string;
  publicPrivateKey?: string;
  secretKey?: string;
  locationId?: string;
  userApiKey?: string;
  userId?: string;
  developerId?: string;
  partnerID?: string;
  feeType: string;
  feeAmount: string;
}

interface ContractorField {
  contractorName: string;
  contractorID: string;
  splitPercentage: string;
  anonymousName: string;
}

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  editData: any;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const AddEditInvoke = ({ show, handleClose, editData }: Props) => {
  // State declarations
  const [selectedBookingProvider, setSelectedBookingProvider] = useState<string>("");
  const [paymentProviders, setPaymentProviders] = useState<PaymentProvider[]>([]);
  const [contractorProviders, setContractorProviders] = useState<PaymentProvider[]>([]);
  const [paymentProviderFields, setPaymentProviderFields] = useState<PaymentProviderField[]>([{
    provider: "",
    feeType: "$",
    feeAmount: "",
  }]);
  const [contractorFields, setContractorFields] = useState<ContractorField[]>([{
    contractorName: "",
    contractorID: "",
    splitPercentage: "0",
    anonymousName: ""
  }]);
  const [isAddContractorEnabled, setIsAddContractorEnabled] = useState(false);
  // For Submit
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
// For Submit
const validateForm = () => {
  // Check if "fortis" is the selected payment provider
  const isFortisSelected = paymentProviderFields.some(provider => provider.provider === "fortis");
  
  // If "fortis" is selected, enable the submit button without validation
  if (isFortisSelected) {
    setIsSubmitDisabled(false);
    return;
  }
  
  // Otherwise, proceed with normal validation
  // const isProviderSelected = paymentProviderFields.some(provider => provider.provider !== "");
  const isContractorSelected = contractorFields.some(field => field.contractorName !== "" && field.contractorID !== "");
  setIsSubmitDisabled(!(isContractorSelected));
};
  // Mutations
  const {
    mutateAsync: getRestaurantData,
    data: restaurantData,
    isLoading: isGettingRestaurantData,
  } = useMutation("get-golf", getAllPaymentProviderInfoBasedOnCategory);

  const {
    data,
    isLoading,
    error,
    mutateAsync: getproviderData
  } = useMutation("paymentProvider", getPaymentProvider);

  const {
    mutateAsync: addRestaurantCategory,
    isLoading: isAddingGolf,
  } = useMutation("add-restaurant", addRestaurantProvider);

  // Initial data fetch
  useEffect(() => {
    const getGolf = async () => {
      if (editData?._id) {
        await getRestaurantData(editData._id);
      }
    };
    getGolf();
  }, [editData]);

  useEffect(() => {
    if (editData?._id) {
      getproviderData({ catId: typeof(editData?.cateId) === "string" ? editData?.cateId :  editData?.cateId?._id });
    }
  }, [editData]);

 // For Submit
  useEffect(() => {
    validateForm();
  }, [paymentProviderFields, contractorFields]);

useEffect(() => {
  if (restaurantData?.data) {
    const paymentInfoArray = restaurantData.data.paymentInfo || [];
    const bookingInfoArray = restaurantData.data.bookingInfo || [];
    // Initialize payment providers
    const initialPaymentProviders = paymentInfoArray.flatMap((paymentInfoObj: any) => {
      return Object.entries(paymentInfoObj).map(([providerName, providerData]: [string, any]) => ({
        provider: providerName,
        merchantID: providerData.fields?.accountId || '',
        publicPrivateKey: providerData.fields?.public_private_key || '',
        secretKey: providerData.fields?.secret_key || '',
        locationId: providerData.fields?.accountId || '',
        userApiKey: providerData.fields?.publicPrivateKey || '',
        userId: providerData.fields?.secretKey || '',
        developerId: providerData.fields?.developerId || '',
        feeType: providerData.fields.flatFees !== undefined ? '$' : '%',
        feeAmount: (providerData.fields.flatFees || providerData.fields.perFlatFees || '').toString(),
      }));
    });

    setPaymentProviderFields(initialPaymentProviders.length > 0 ? initialPaymentProviders : [{
      provider: "",
      feeType: "$",
      feeAmount: "",
    }]);

    // Initialize contractor fields
    const firstPaymentInfoObj = paymentInfoArray[0] || {};
    const firstProviderName = Object.keys(firstPaymentInfoObj)[0];
    const firstProvider = firstPaymentInfoObj[firstProviderName];
    const contractorFieldsData = firstProvider?.fields?.contractorFields || [];
    if (contractorFieldsData.length > 0) {
      setContractorFields(contractorFieldsData.map((field: any) => ({
        contractorName: field.contractorName,
        contractorID: field.contractorFieldId,
        splitPercentage: field.contractorPer,
        anonymousName: field.name || '',
      })));
    }

    // Initialize booking provider
    const firstBookingInfoObj = bookingInfoArray[0] || {};
    const bookingProviderName = Object.keys(firstBookingInfoObj)[0] || "";
    setSelectedBookingProvider(bookingProviderName);
    if (contractorFieldsData.length > 0) {
     // Enable the add button after adding a new field
     setIsAddContractorEnabled(true);  
    }
    // Set initial values for formik
    formik.setValues({
      booking_provider: bookingProviderName,
      venueID: firstBookingInfoObj[bookingProviderName]?.fields?.venueId || "",
      perForMerchant:restaurantData?.data?.perForMerchant || 0,
    });
  }
}, [restaurantData]);
  useEffect(() => {
    if (data?.paymentProviders) {
      setPaymentProviders(data.paymentProviders);
    }
    if (data?.contractorProviders) {
      setContractorProviders(data.contractorProviders);
    }
  }, [data]);


  const handleRemovePaymentProvider = (indexToRemove: number) => {
    if (paymentProviderFields.length === 1) return;
    setPaymentProviderFields(paymentProviderFields.filter((_, index) => index !== indexToRemove));
  };

  const handlePaymentProviderChange = (index: number, field: string, value: string) => {
    const updatedFields = paymentProviderFields.map((providerField, idx) => {
      if (idx === index) {
        if (field === 'provider') {
          return {
            ...providerField,
            [field]: value,
            merchantID: '',
            publicPrivateKey: '',
            secretKey: '',
            locationId: '',
            userApiKey: '',
            userId: '',
            developerId: '',
          };
        }
        return { ...providerField, [field]: value };
      }
      return providerField;
    });
    setPaymentProviderFields(updatedFields);
    // For Submit
    validateForm();
  };

  const handleAddContractorField = () => {
    setContractorFields([
      ...contractorFields,
      { contractorName: "", contractorID: "", splitPercentage: "0", anonymousName: "" },
    ]);
  // Disable the add button after adding a new field
  setIsAddContractorEnabled(false);
  };

const handleRemoveContractorField = (indexToRemove: number) => {
  if (contractorFields.length === 1) return;
  setContractorFields(contractorFields.filter((_, index) => index !== indexToRemove));
};


const handleContractorFieldChange = (index: number, field: string, value: string) => {

  const updatedFields = contractorFields.map((contractor, idx) => {
    if (idx === index) {
      const updatedContractor = { ...contractor, [field]: value };
      if (field === 'contractorName') {
        // Check if the contractor is already selected
        const isAlreadySelected = contractorFields.some((c, i) => i !== index && c.contractorName === value);
        if (isAlreadySelected) {
          window.alert("This contractor is already selected. Please choose a different one.");
          return contractor; // Return the original contractor without changes
        }
        // Clear anonymousName if the selected contractor is not "anonymousName"
        updatedContractor.anonymousName = value === 'Brand Name' ? contractor.anonymousName : '';

      }
      return updatedContractor;
    }
    return contractor;
  });

  
  setContractorFields(updatedFields);
  
  // Check if the last contractor field is filled correctly
  const lastContractor = updatedFields[updatedFields.length - 1];
  const isContractorSelected = lastContractor.contractorName !== "";
  const isContractorIdValid = lastContractor.contractorID.trim() !== "";
  setIsAddContractorEnabled(isContractorSelected && isContractorIdValid);
  // For Submit
  validateForm();
};
const handleBookingProviderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  const provider = e.target.value;
  setSelectedBookingProvider(provider);
  formik.setFieldValue('booking_provider', provider);
};


// Formik initialization
const formik = useFormik({
  initialValues: {
    booking_provider: "",
    venueID: "",
    perForMerchant: restaurantData?.data?.perForMerchant || 0,
  },
  onSubmit: async (values) => {
 // Transform payment provider fields into the required format
 const paymentInfo = paymentProviderFields.map((provider) => {
  const providerData: any = {
    [provider.provider]: {
      fields: {
        ...(provider.provider === "fiserv" && {
          accountId: provider.merchantID,
          public_private_key: provider.publicPrivateKey,
        }),
        ...(provider.provider === "shift4" && {
          secret_key: provider.secretKey,
        }),
        ...(provider.provider === "fortis" && {
          developerId: provider.developerId,
          publicPrivateKey: provider.userApiKey,
          secretKey: provider.userId,
          accountId: provider.locationId,
        }),
        ...(provider.feeType === "$" 
          ? { flatFees: Number(provider.feeAmount) } 
          : { perFlatFees: Number(provider.feeAmount) }),
        // Move contractorFields inside the fields object
        ...(provider.provider !== "fortis" && {
        contractorFields: contractorFields.map((field) => ({
          contractorFieldId: field.contractorID,
          contractorName: field.contractorName,
          contractorPer: Number(field.splitPercentage),
          ...(field.contractorName === "Brand Name" && { name: field.anonymousName }),
        })),
      }),
      },
    },
  };
  return providerData;
});

const bookingProviderData = {
  [values.booking_provider]: {
    fields: {
      venueId: values.venueID,
      accountId: values.booking_provider,
    },
  },
};

  
    try {
      const response = await addRestaurantCategory({
        categoryId: editData?.cateId?._id ?? editData?.cateId,
        itemId: editData?._id,
        paymentInfo,
        bookingInfo: [bookingProviderData],
        booking_provider: values.booking_provider,
        perForMerchant: values.perForMerchant,
      });

      if (response?.status) {
        snackActions.success(response?.message ?? "Restaurant Added Successfully!");
        formik.resetForm();
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    } catch (error) {
      snackActions.error("An error occurred while saving the data.");
    }
  },
});

const isFortiSelected = paymentProviderFields[0].provider === "fortis" && data?.bookingProviders && data.bookingProviders.length >= 0 
// Render method
return createPortal(
  <Modal
    id="kt_modal_create_app"
    tabIndex={-1}
    aria-hidden="true"
    dialogClassName="modal-dialog modal-dialog-centered mw-900px scroll"
    show={show}
    onHide={() => handleClose(false)}
    backdrop="static"
    size="xl" 
  >
    <div className="modal-header">
      <h2>Add/Edit Involved Party</h2>
      <div
        className="btn btn-sm btn-icon btn-active-color-primary"
        onClick={() => handleClose(false)}
      >
        <KTIcon className="fs-1" iconName="cross" />
      </div>
    </div>
    <div style={{ 
      // height: isFortiSelected ? "calc(100vh - 48vh)" : "calc(100vh - 35vh)", 
      maxHeight:"80vh",
      overflowY: "auto" 
                }}>
    <div className="p-3 p-sm-6">
      <form onSubmit={formik.handleSubmit} noValidate className="form">
        <Container fluid>

            

          {/* Payment Providers Section */}
                    {/* <Row> */}
                     {/* <Col xs={12}> */}
                     {/* <h4 className="mb-4">Payment Provider {index + 1}</h4> */}
                      {/* <h4 >Payment Provider </h4> */}
                      {/* </Col> */}
                      {/* <Col xs={12} md={6} className="mt-4 mb-4"> */}
                      {/* <label className="form-label fw-bold"> */}
                      {/* Split percentage for merchant */}
                      {/* </label> */}
                        {/* <input
                        type="number"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Split percentage for merchant"
                        {...formik.getFieldProps("perForMerchant")}
                      />
                      
                    </Col>
                    </Row>
         */}

{/* <div className="border-top"></div> */}
          {/* <Button
            variant="primary"
            className="mb-4"
            onClick={handleAddPaymentProvider}
          >
            Add Payment Provider
          </Button>  */}

{!paymentProviderFields.some(provider => provider.provider.toLowerCase() === "fortis") && (
     <>
         <Row className="mt-5 mb-5">
         <Col xs={12} className="d-flex align-items-center">
  <div >
    <span style={{fontWeight: "600" , fontSize: "1.25rem"}}>Involved Party</span>
    </div>
    {/* <Button
      variant="primary"
      onClick={handleAddContractorField}
    > */}
     <div 
          className="d-flex align-items-center justify-content-center"
          style={{ 
            width:"30px", 
            height:"30px", 
            backgroundColor: isAddContractorEnabled ? "#3D1A91" : "#ccc", 
            borderRadius:"50%", 
            color: "white", 
            marginLeft:"10px", 
            cursor: isAddContractorEnabled ? "pointer" : "not-allowed",
            opacity: isAddContractorEnabled ? 1 : 0.5
          }}
          onClick={isAddContractorEnabled ? handleAddContractorField : undefined}
        >
          <AddIcon />
        </div>
    {/* </Button> */}
  </Col>
  {/* <Col xs={1}> */}
   
  {/* </Col> */}
</Row>
  {contractorFields.map((field, index) => (

    
    <div key={index}>
      <Row className={`mb-2 ${index > 0 ? "border-top pt-3" : ""}`}>
    
      <Col xs={12} md={6} className="mb-3 mt-3">
      
        <label className="form-label fw-bold">Select Reservation Providers</label>
        <select
          className="form-select form-select-solid form-select-lg"
          value={field.contractorName}
          onChange={(e) =>
            handleContractorFieldChange(index, "contractorName", e.target.value)
          }
        >
          <option value="">Select Reservation Provider</option>
          {data?.contractorProviders?.map((provider:any) => (
            <option value={provider.name} key={provider.name}>
              {provider.name}
            </option>
          ))}
        </select>
      </Col>
      {/* <Col xs={contractorFields.length > 1 ? 5 : 6} className="mb-3 mt-3"> */}
      <Col xs={12} md={contractorFields.length > 1 ? 5 : 6} className="mb-3 mt-3">
        <label className="form-label fw-bold">Contractor ID</label>
        <input
          type="text"
          className="form-control form-control-lg form-control-solid"
          placeholder="Contractor ID"
          value={field.contractorID}
          onChange={(e) =>
            handleContractorFieldChange(index, "contractorID", e.target.value)
          }
        />
      </Col>
      {contractorFields.length > 1 && (
     <Col xs={2} md={1} className="mb-3 d-flex flex-column justify-content-end">
          <Button
            className="p-3"
            variant="danger"
            onClick={() => handleRemoveContractorField(index)}
          >
            <KTIcon iconName="trash" className="fs-2" />
          </Button>
        </Col>
      )}
      </Row>
      <Row className="mb-2">

 
      <Col xs={12} md={6} className="mb-3">
        <label className="form-label fw-bold">Split Percentage</label>
        <input
          type="number"
          className="form-control form-control-lg form-control-solid"
          placeholder="Split Percentage"
          value={field.splitPercentage}
          onChange={(e) =>
            handleContractorFieldChange(index, "splitPercentage", e.target.value)
          }
          min="0"
          max="100"
        />

      </Col>
      
      
      {field.contractorName === "Brand Name" && (
        

         <Col xs={12} md={6} className="mb-3">
          
          <label className="form-label fw-bold">Brand Name</label>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            placeholder="Brand Name"
            value={field.anonymousName}
            onChange={(e) =>
                
              handleContractorFieldChange(index, "anonymousName", e.target.value)
            }
          />
        </Col>
      )}
      </Row>

    </div>
  ))}

</>
 )}



          </Container>
          <div className="w-100 d-flex p-5 align-items-center justify-content-end mt-5">
            <Button
              onClick={() => {
                formik.resetForm();
                handleClose(false);
              }}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            {/* <Button type="submit" size="lg" variant="primary"> */}
            <Button type="submit" size="lg" variant={isSubmitDisabled ? "secondary" : "primary"}  disabled={isSubmitDisabled} >
              {/* {isAddingEditing ? "Loading..." : "Submit"} */}
              Submit
            </Button>
          </div>
        </form>
      </div>
</div>

    </Modal>,
    modalsRoot,
  );
};

export default AddEditInvoke;
