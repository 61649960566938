import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { KTCard, KTIcon } from "../../_metronic/helpers";
import CustomTooltip from "../../_metronic/layout/components/common/CustomTooltip";
import { PageTitle } from "../../_metronic/layout/core";
import { activeDeActiveGolfClub, deleteGolfClub, deleteRestrictedSlot, deleteWidgetDetails, editAdmin, getGolfClub, getRestaurantInfo, getSlotDetailsById, getWidgetDetails } from "../../api";
import AddEditHoles from "../../components/holes/AddEditHoles";
import MuiTable from "../../components/table/MuiTable";
import AddEditProvider from "../../components/third-provider/AddEditProvider";
import AddEditInvoke from "../../components/third-provider/AddEditInvoke";
import { charValidate } from "../../components/utility";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import AddEditSlot from "../../components/slot-restriction/AddEditSlot";
import { parseDateWithoutTimezone } from "../../helpers/commonFunctions";
import RestaurantIcon from "@mui/icons-material/GolfCourse";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { de } from "date-fns/locale";
import TokenGeneration from "../../components/slot-restriction/TokenGeneration";
import { parseDateWithoutTimezoneMMDDYY } from "../../constants/constant";

const RestaurantInfo: React.FC = () => {
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [generateTokenModal, setShowGenerateTokenModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] =
    useState<boolean>(false);
  const [showGolfImgPopup, setShowGolfImgPopup] = useState<boolean>(false);
  const [showAddEditAPIPopup, setShowAddEditAPIPopup] =
    useState<boolean>(false);
  const [showAddEditAPIPopupInvoke, setShowAddEditAPIPopupInvoke] =
    useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [hasFortisState, setHasFortisState] = useState<boolean>(false);
  const [selectedGolfImg, setSelectedGolfImg] = useState<string>("");

  const tableRef = useRef();
  const location: any = useLocation();
  const golfDetailInfo = location?.state;
  const isForTokenGeneration = location?.state?.isForTokenGeneration
  const paymentFlag = location?.state?.payment; // Access the payment flag here
  const navigate = useNavigate();
  const {
    mutateAsync: getRestaurantData,
    data: restaurantData,
    isLoading: isGettingRestaurantData,
    error,
  } = useMutation("get-golf", getRestaurantInfo);
  const { data, isLoading, mutateAsync: getSlotdetails } = useMutation("slot-details", getSlotDetailsById);
  const {
    mutateAsync: deleteGolfData,
    isLoading: isdeletingGolf,
    error: deleteGoldAPIError,
  } = useMutation("delete-golf", deleteRestrictedSlot);

  const {
    mutateAsync: deleteWidgetData,
    isLoading: isdeletingWidget,
    error: deleteWidgetAPIError,
  } = useMutation("delete-widget", deleteWidgetDetails);

    // Loading state for fetching slots
    const loading = isGettingRestaurantData || isLoading || isdeletingGolf || isdeletingWidget;

  useEffect(() => {
    getGolf();
  }, []);
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    restaurantId: restaurantData?.data?._id,
    totalRowCount: undefined
  });

  useEffect(() => {
    if (restaurantData?.data?._id) {
      getSlotdetails({ ...tableOptions, restaurantId: restaurantData?.data?._id })
    }
  }, [restaurantData?.data?._id])

  useEffect(() => {
    if (restaurantData?.data) {
      const paymentInfo = restaurantData.data.paymentInfo;
      // Check if fortis exists or if paymentInfo is an empty array
      const fortisExists = paymentInfo?.length === 0 || paymentInfo?.some((payment: any) => payment?.fortis) ||  paymentInfo?.every((payment: any) => Object.keys(payment).length === 0);
        setHasFortisState(fortisExists);
    }
  }, [restaurantData?.data]);

  const htmlString = restaurantData?.data?.description
    ? charValidate(restaurantData?.data?.description, 200)
    : "";
  const descriptionElements = { __html: htmlString };

  const getGolf = async () => {
    await getRestaurantData(golfDetailInfo?.restaurantData?._id ?? "");
  };

  const slotRestrictionData = data?.restrictions

  const columns: GridColDef[] = [
    {
      field: "significantDemandTitle",
      headerName: "Significant Demand Title",
      sortable: false,
      flex:1,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) => data?.row?.name ?? "N/A",
    },
    {
      field: "startdate",
      headerName: "Start Date",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => parseDateWithoutTimezoneMMDDYY(data?.row?.startDate),
    },
    {
      field: "enddate",
      headerName: "End Date",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => parseDateWithoutTimezoneMMDDYY(data?.row?.endDate),
    },
    {
      field: "slotdetails",
      headerName: "Slot Details",
      flex: 2,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => {
        const days = data?.row?.days;

        if (!days || days.length === 0) {
          return <div>N/A</div>;
        }

        // Format the slot details vertically
        const formattedDetails = days.map((day: any) => {
          const dayName = day.day;
          const timeRanges = day.timeRanges.map((timeRange: any) =>
            `${timeRange.startTime} - ${timeRange.endTime}`
          ).join(", ");

          return (
            <div key={dayName}>
              {dayName}: {timeRanges}
            </div>
          );
        });

        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {formattedDetails}
          </div>
        );
      }
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.7,
      sortable: false,
      headerAlign: "right",
      align: "right", 
      cellClassName:"token-actions-align",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Edit">
            <DriveFileRenameOutlineIcon
              onClick={() => {
                setSelectedRow(data?.row);
                setShowAddEditModal(true);
              }}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteSweepIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowDeleteConfirmModal(true);
              }}
              sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
              color="error"
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  const renderProviderFields = (fields: any, provider?: string, isBookingInfo: boolean = false) => {
    if (!fields) return null;
    // Define a mapping for specific keys and their corresponding display labels
    const keyLabelMapping: { [key: string]: string } = {
      developerId: "Merchant Developer ID",
      publicPrivateKey: "Merchant API Key",
      secretKey: "Merchant User ID",
      accountId: provider?.toLowerCase() === 'fiserv' ? "Merchant ID" : "Merchant Location ID",
      public_private_key: "Private Key",
      flatFees: "Flat Fees",
      venueId: provider?.toLowerCase() === "book4times" ? "Location ID" : "Venue ID",
    };
  
    // Filter and map the fields
    const filteredFields = Object.entries(fields).filter(([key]) => {
      // Always filter out these fields
      if (['flatFees', 'perFlatFees'].includes(key)) {
        return false;
      }
      // Filter out accountId only in booking information
      if (isBookingInfo && key === 'accountId') {
        return false;
      }
      return true;
    });
    return filteredFields.map(([key, value]: [string, any]) => {
      // Special handling for 'contractorFields'
      if (key === 'contractorFields' && Array.isArray(value)) {
        return (
          <div key={key} className="mt-2">
            <div className="text-muted mb-2" style={{ fontWeight: "bold" }}>Involved Party:</div>
            {value.map((contractor: any, index: number) => (
              <div key={index} className="ml-4 mb-2">
                {contractor.name && <div><span style={{ fontWeight: "bold" }}>Brand Name: </span>{contractor.name ?? "N/A"}</div>}
                {contractor.contractorName &&<div><span style={{ fontWeight: "bold" }}>Selected Reservation Provider: </span>{contractor.contractorName ?? "N/A"}</div>}
                {contractor.contractorFieldId && <div><span style={{ fontWeight: "bold" }}>Contractor ID: </span>{contractor.contractorFieldId ?? "N/A"}</div>}
                <div><span style={{ fontWeight: "bold" }}>Split Percentage: </span>{contractor.contractorPer}{contractor.contractorPer ? "%": ""}</div>
                {index !== value.length - 1 && <hr />} 
              </div>
            ))}
          </div>
        );
      }
  
      // Use the keyLabelMapping to display custom labels if the key exists in the mapping
      const displayLabel = keyLabelMapping[key] || key.replace(/_/g, " ")?.charAt(0).toUpperCase() + key?.slice(1);
      
      return (
        <div key={key} className="mb-1">
          <span style={{ fontWeight: "bold" }}>
            {displayLabel}
              {/* {key} */}
          </span>
          :
          <span style={{ marginLeft: "5px" }}>{(!value || value === "") ? "N/A" : value}</span>
        </div>
      );
    });
  };
  const renderProviderInfo = (info: any, isBookingInfo: boolean = false) => {
    if (!info || !Array.isArray(info) || info.length === 0) return null;
  
    return info.map((item, index) => {
      const provider = Object.keys(item)[0];
      const providerData = item[provider];
      return (
        <div key={index} className="mb-4">
         {provider ? <div className="font-weight-bold mb-2" style={{fontWeight: "bold"}}>
            {provider === "fiserv" || provider === "fortis" || provider === "shift4" || provider === "stripe" 
              ? "Selected Payment gateway" 
              : "Booking Provider"}  : {provider ? provider?.charAt(0).toUpperCase() + provider?.slice(1) : ""}
          </div> : "No Provider Available"}
          <div className="pl-3">
            {/* <div> <span style={{fontWeight: "bold"}}>Provider ID:  </span>{providerData.providerId ?? "N/A"}</div> */}
            {/* {providerData.perFlatFees !== undefined && (
              <div> <span style={{fontWeight: "bold "}}>Per Flat Fees:  </span> {providerData.perFlatFees ?? "N/A"}%</div>
            )}
            {providerData.flatFees !== undefined && (
              <div> <span style={{fontWeight: "bold"}}>Flat Fees: </span>${providerData.flatFees ?? "N/A"}</div>
            )} */}
            {providerData?.fields && (
              <div className="mt-2">
                <div className="text-muted mb-1" style={{fontWeight: "bold"}}>Fields:</div>
                <div className="pl-3">
                {renderProviderFields(providerData?.fields, provider, isBookingInfo)}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    });
  };
  

  // const RestaurantDetailsCard = () => (
  //   <Card style={{ width: "100%", boxShadow: "none", marginBottom: "20px"  }}>
  //     <Card.Body  >
  //       <Card.Title className="pb-5">Restaurant Details</Card.Title>
  //       <Container style={{ marginLeft: 0 }}>
  //         <Row>
  //           <Col xs={6} style={{ paddingLeft: 0 }}>
  //             <Card.Subtitle className="text-muted">Name:</Card.Subtitle>
  //             <Card.Text>{restaurantData?.data?.name ?? "N/A"}</Card.Text>
  //           </Col>
  //           <Col xs={6} style={{ paddingLeft: 0 }}>
  //             <Card.Subtitle className="text-muted">Address:</Card.Subtitle>
  //             <Card.Text>{`${restaurantData?.data?.address?.name}, ${restaurantData?.data?.address?.street}, ${restaurantData?.data?.address?.city}, ${restaurantData?.data?.address?.state}, ${restaurantData?.data?.address?.zip ?? "N/A"}`}</Card.Text>
  //           </Col>
  //         </Row>
  //         <Row className="mt-5">
  //           <Col xs={6} style={{ paddingLeft: 0 }}>
  //             <Card.Subtitle className="text-muted">Description:</Card.Subtitle>
  //             <Card.Text className="mt-1" style={{ maxHeight: "30vh"}}>
  //               <div dangerouslySetInnerHTML={descriptionElements} />
  //             </Card.Text>
  //           </Col>
  //         </Row>
  //       </Container>
  //     </Card.Body>
  //   </Card>
  // );

  const PaymentInfoCard = () => (
    <Card style={{ height: "100%", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 20px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px" }}>
      <Card.Body>
        <Card.Title className="pb-5">Payment Information</Card.Title>
        <Container style={{ marginLeft: 0, paddingLeft: 0 }}>
          {restaurantData?.data?.paymentInfo && 
            (Array.isArray(restaurantData.data.paymentInfo) && restaurantData.data.paymentInfo.length > 0) ? (
              renderProviderInfo(restaurantData.data.paymentInfo, false) /* Explicitly passing false */
            ) : (
              <>
              <div className="text-muted">No payment information available</div>
              </>
            )}
        </Container>
      </Card.Body>
    </Card>
  );
  // const BookingInfoCard = () => (
  //   <Card style={{ height: "100%", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 20px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px" }}>
  //     <Card.Body>
  //       <Card.Title className="pb-5">Booking Information</Card.Title>
  //       <Container style={{ marginLeft: 0 ,  paddingLeft: 0}}>
  //         {restaurantData?.data?.bookingInfo ? (
  //           <>
  //          {renderProviderInfo(restaurantData.data.bookingInfo)}
  //           <div><span style={{fontWeight: "bold"}}>Split percentage for merchant:  </span> {restaurantData.data.perForMerchant ?? "N/A"}</div>
  //           </>
  //         ) : (
  //           <div className="text-muted">No booking information available</div>
  //         )}
  //       </Container>
  //     </Card.Body>
  //   </Card>
  // );
  const BookingInfoCard = () => {
    const hasBookingInfo = restaurantData?.data?.bookingInfo && 
      (Array.isArray(restaurantData.data.bookingInfo) 
        ? restaurantData.data.bookingInfo.length > 0 
        : Object.keys(restaurantData.data.bookingInfo).length > 0);
  
    return (
      <Card style={{ height: "100%", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 20px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px" }}>
        <Card.Body>
          <Card.Title className="pb-5">Booking Information</Card.Title>
          <Container style={{ marginLeft: 0, paddingLeft: 0 }}>
            {hasBookingInfo ? (
              <>
                {renderProviderInfo(restaurantData.data.bookingInfo, true)} {/* Explicitly passing true */}
                <div>
                  <span style={{ fontWeight: "bold" }}>Split percentage for merchant: </span> 
                  {restaurantData.data.perForMerchant   ?? "N/A" }{restaurantData.data.perForMerchant ? "%" : ""}
                </div>
              </>
            ) : (
              <div className="text-muted">No booking information available</div>
            )}
          </Container>
        </Card.Body>
      </Card>
    );
  };
  const InfoCardsContainer = () => (
    <div className="mt-4">
      <Row>
        <Col md={6} className="pe-3">
          <PaymentInfoCard />
        </Col>
        <Col md={6} className="ps-3">
          <BookingInfoCard />
        </Col>
      </Row>
    </div>
  );
  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    setSelectedRow("");
    if (isSubmit) {
      getGolf();
    }
  };

  const deleteConfirmModal = (data: any) => {
    return (
      <Modal
        id="kt_modal_create_app"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-400px scroll"
        show={showDeleteConfirmModal}
        onHide={handleCloseDelete}
        backdrop="static"
      >
        <div className="modal-header">
          <h2>Delete Restricted Slot</h2>
          {/* begin::Close */}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={handleCloseDelete}
          >
            <KTIcon className="fs-1" iconName="cross" />
          </div>
          {/* end::Close */}
        </div>
        <div className="d-flex flex-column align-items-center">
          <div className="p-5">
            <h4>Are you sure, you want to delete the slot?</h4>
          </div>
          <div className="w-100 d-flex p-5 align-items-center  justify-content-center">
            <Button
              disabled={isdeletingGolf}
              onClick={handleCloseDelete}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            <Button
              disabled={isdeletingGolf}
              onClick={deleteGolf}
              size="lg"
              variant="primary"
            >
              {isdeletingGolf || isdeletingWidget ? "Loading..." : "Submit"}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const golfImagePopup = () => (
    <Modal
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-400px scroll"
      show={showGolfImgPopup}
      onHide={() => setShowGolfImgPopup(false)}
      backdrop={true}
    >
      <Image src={selectedGolfImg} fluid />
    </Modal>
  );


  const handleAddEditAPIModalClose = () => {
    setShowAddEditAPIPopup(false);
       // Refresh the restaurant data when the modal closes
       getGolf();
  };
  const handleAddEditAPIModalCloseInvoke = () => {
    setShowAddEditAPIPopupInvoke(false);
      getGolf(); 
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
    setShowGenerateTokenModal(false)
  };

  const deleteGolf = async () => {
    let response = await deleteGolfData({
      restaurantId: restaurantData?.data?._id,
      restrictionId: selectedRow?._id
    });
    if (response?.status) {
      snackActions.success(response?.message);
      handleCloseDelete();
      getGolf();
    } else {
      snackActions.error(response?.message);
    }
  };

  const handleBackClick = () => {
    navigate("/restaurant_list");
  };

  const getSlots = async (
    query:
      | {
        offset: number;
        limit: number;
      }
      | undefined
  ) => {
    let response = await getSlotdetails(query)
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          restaurantId: restaurantData?.data?._id,
          totalRowCount: response?.data?.total,
        };
      });
    }
  };

  useEffect(() => {
    if (data) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: data?.offset,
          limit: data?.limit,
          restaurantId: restaurantData?.data?._id,
          totalRowCount: data?.total,
        };
      });
    }
  }, [data])

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        Number(pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0),
      limit: pageSize ?? 10,
      restaurantId: restaurantData?.data?._id
    };
    getSlots(query);
  };

  const GolfDetailsCard = () => {
    return (
      <Card style={{ width: "100%", boxShadow: "none", marginTop: "40px" }} className="mb-4">
        <Card.Body>
          <Card.Title className="pb-5">Restaurant Details</Card.Title>
          <Container style={{marginLeft:0}}>
            <Row>
              <Col xs={6} style={{ paddingLeft: 0 }}>
                <Card.Subtitle className="text-muted">Name:</Card.Subtitle>
                <Card.Text>{restaurantData?.data?.name ?? "N/A"}</Card.Text>
              </Col>
              {/* <Col xs={6}>
                <Card.Subtitle className="text-muted">Time:</Card.Subtitle>
                <Card.Text>
                  {restaurantData?.data?.startTime && restaurantData?.data?.endTime
                    ? `${restaurantData?.data?.startTime} - ${restaurantData?.data?.endTime}`
                    : "N/A"}
                </Card.Text>
              </Col> */}
              <Col xs={6}>
                <Card.Subtitle className="text-muted">Address:</Card.Subtitle>
                <Card.Text>{`${restaurantData?.data?.address?.name}${", "}${restaurantData?.data?.address?.street}${", "}${restaurantData?.data?.address?.city}${", "}${restaurantData?.data?.address?.state}${", "}${restaurantData?.data?.address?.zip ?? "N/A"}`}</Card.Text>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col xs={6} style={{ paddingLeft: 0 }}>
                <Card.Subtitle className="text-muted" >
                  Description:
                </Card.Subtitle>
                <Card.Text className="mt-1" style={{maxHeight: "30vh"}}>
                  <div dangerouslySetInnerHTML={descriptionElements} />
                </Card.Text>
              </Col>
              {/* <Col xs={6}>
                <Card.Subtitle className="text-muted">Type:</Card.Subtitle>
                <Card.Text>{restaurantData?.data?.type ?? "N/A"}</Card.Text>
              </Col> */}
            </Row>
          </Container>
        { !paymentFlag  && <Row className="mt-5">
            <Card.Subtitle style={{color:"black !important", fontSize:"14.95px"}}>
              Slot Restriction:
            </Card.Subtitle>
          </Row>}
        </Card.Body>
      </Card>
    );
  };

  return (
    <Card className="p-6">
      <PageTitle>{golfDetailInfo?.restaurantData?.name ?? ""}</PageTitle>
      <>
        <KTCard>
          {!paymentFlag ? (
            loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '300px' }}>
                <CircularProgress />
              </Box>
            ) : (
              <MuiTable
                addLabel="Slot Restriction"
                handleAddClick={() => setShowAddEditModal(true)}
                columns={columns}
                data={slotRestrictionData}
                tableRef={tableRef}
                loading={isGettingRestaurantData}
                isBackEnable={true}
                handleBackClick={handleBackClick}
                tableOptions={tableOptions}
                handlePagination={handlePagination}
                isForRestaurant={true}
                isForTokenGeneration={false}
              >
             <GolfDetailsCard />
              </MuiTable>
            )
          ) : (
            <>
             { loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '300px' }}>
                <CircularProgress />
              </Box>
            ) : ( <> <div className="pb-5 px-5 d-flex align-items-center" style={{width:"100%"}}>
              <div style={{width:"43%"}}>
                <Button
                  variant="secondary"
                  className="d-flex align-items-center"
                  onClick={handleBackClick}
                >
                  <KTIcon iconName="arrow-left" className="fs-1" />
                  Back
                </Button>
                </div >
                  <div className="d-flex align-items-center justify-content-end" style={{width:"57%"}}>
                <Button
                  className="d-flex align-items-center ms-3"
                  onClick={() => setShowAddEditAPIPopup(true)}
                >
                  <KTIcon iconName="plus" className="fs-1" />
                  Add/Edit Payment & Booking Provider
                </Button>
               {!hasFortisState  && <Button
                  className="d-flex align-items-center ms-3"
                  onClick={() => setShowAddEditAPIPopupInvoke(true)}
                >
                  <KTIcon iconName="plus" className="fs-1" />
                  Add/Edit Involved Party
                </Button>}
                </div>
                </div>
                <GolfDetailsCard />
                <InfoCardsContainer /> </> )}
        </>
        )}
      </KTCard>
      
        {showAddEditModal && (
          <AddEditSlot
            editData={selectedRow}
            show={showAddEditModal}
            handleClose={handleModalClose}
            restaurantData={restaurantData?.data}
          />
        )}
        {showDeleteConfirmModal && deleteConfirmModal(selectedRow)}
        {showGolfImgPopup && golfImagePopup()}
      </>
      
      {showAddEditAPIPopup && (
          <AddEditProvider
          show={showAddEditAPIPopup}
          handleClose={handleAddEditAPIModalClose}
          editData={restaurantData?.data}       
          // editData={golfDetailInfo?.restaurantData}    
          />

       )}
     {!hasFortisState  && showAddEditAPIPopupInvoke && (
    <AddEditInvoke
        show={showAddEditAPIPopupInvoke}
        handleClose={handleAddEditAPIModalCloseInvoke}
        editData={restaurantData?.data}       
        // editData={golfDetailInfo?.restaurantData}    
    />
)}
    </Card>
  );
};

export default RestaurantInfo;
