import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import { useMutation, useQuery } from "react-query";
import "react-quill/dist/quill.snow.css";
import { KTIcon } from "../../_metronic/helpers";
import {
  getAllGolfClub,
  getAllHealthCareList,
  getAllHotelList,
  getAllWellnessList,
  getCategories,
  getCategoryRestaurant,
} from "../../api";

type Props = {
  show: boolean;
  details: any;
  isClose: boolean;
  handleClose: (isSubmit: boolean, obj: any, isClose: boolean) => void;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const FilterDashboard = ({ show, handleClose, details }: Props) => {
  const [selectedStartDate, setSelectedStartDate] =
    useState<dayjs.Dayjs | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<dayjs.Dayjs | null>(
    null
  );
  const [selectedStartTime, setSelectedStartTime] = useState<Date | null>(null);
  const [selectedEndTime, setSelectedEndTime] = useState<Date | null>(null);
  const [selectedItemsValue, setSelectedItemsValue] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [selectedPerson, setSelectedPerson] = useState("");

  const [selectedStartTimeValue, setSelectedStartTimeValue] = useState("");
  const [selectedEndTimeValue, setSelectedEndTimeValue] = useState("");
  const [isValid, setValidationValue] = useState(true);
  const [categoryIdOnChange, setCategoryIdOnChange] = useState("");
  // const [IsRestaurants, setIsRestaurants] = useState(false);
  const [itemsData, setItemsData] = useState<any[]>([]);
  const [selectedItemsName, setSelectedItemsName] = useState("");


  const { data: categoryData } = useQuery("category", getCategories);
  
  const defaultQuery = { offset: 0, limit: 1000, search: "", categoryId : "", itemId : "" };

  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    search: "",
    limit: 100,
  });

  const handleStartDateChange = (e: any) => {
    setSelectedStartDate(e);
  };

  const formatDate = (date: dayjs.Dayjs | null) => {
    if (date && date.isValid()) {
      const dd = dayjs(date).format("MM-DD-YYYY");
      return dd;
    }
    return "";
  };

  const handleEndDateChange = (e: any) => {
    setSelectedEndDate(e);
  };

  const handleStartTimeChange = (newValue: any) => {
    setSelectedStartTime(newValue);
    const dateString = formatTime(new Date(newValue.$d));
    setSelectedStartTimeValue(dateString);
  };

  const handleReset = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setSelectedStartTime(null);
    setSelectedEndTime(null);
    setSelectedEndTimeValue("");
    setSelectedStartTimeValue("");
    setSelectedPerson("");
    setSelectedItemsValue("");
    setCategoryIdOnChange("");
    handleClose(false, {}, true);
  };

  function formatTime(date: any) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const addHours =
      formattedHours < 10 ? "0" + formattedHours : formattedHours;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    return `${addHours}:${formattedMinutes} ${ampm}`;
  }

  const handleEndTimeChange = (newValue: any) => {
    setSelectedEndTime(newValue);

    const dateString = formatTime(new Date(newValue.$d));
    setSelectedEndTimeValue(dateString);
  };

  const handleCategoryChange = (e: any) => {
    setCategoryIdOnChange(e);
  };

  const { mutateAsync: getGolfData, data: golfData } = useMutation(
    "golf-list",
    getAllGolfClub
  );

  const { mutateAsync: getRestaurantAPI } = useMutation(
    "restaurant-list",
    getCategoryRestaurant
  );

  const { mutateAsync: getWellNessAPI } = useMutation(
    "wellness-list",
    getAllWellnessList
  );

  const { mutateAsync: getHealthCareAPI } = useMutation(
    "healthCare-list",
    getAllHealthCareList
  );

  const {
    mutateAsync: getHotelCareAPI,
    data: hotelData,
    isLoading: isLoadingHotel,
  } = useMutation("hotel-list", getAllHotelList);

  useEffect(() => {
    (async () => {
      const res =
        categoryIdOnChange === process.env.REACT_APP_ID_REST_CATE
          ? await getRestaurantAPI(defaultQuery)
          : categoryIdOnChange === process.env.REACT_APP_ID_HOTEL_CATE
          ? await getHotelCareAPI(defaultQuery)
          : categoryIdOnChange === process.env.REACT_APP_ID_GOLF_CATE
          ? await getGolfData(defaultQuery)
          : categoryIdOnChange === process.env.REACT_APP_ID_HEALTH_CARE_CATE
          ? await getHealthCareAPI(defaultQuery)
          : categoryIdOnChange === process.env.REACT_APP_ID_SALOON_CATE
          ? await getWellNessAPI(defaultQuery)
          : null;
      setItemsData(res?.data?.docs);
    })();
  }, [categoryIdOnChange]);

  useEffect(() => {
    getGolf(tableOptions);
  }, []);

  useEffect(() => {
    if (details) {
      setSelectedStartDate(dayjs(details?.selectedStartDate));
      setSelectedEndDate(dayjs(details?.selectedEndDate));
      setSelectedPerson(details?.selectedPerson);
      setSelectedStartTime(details?.selectedStartTimeFormat);
      setSelectedEndTime(details?.selectedEndTimeFormat);
      setSelectedStartTimeValue(details?.selectedStartTime);
      setSelectedEndTimeValue(details?.selectedEndTime);
      setSelectedItemsValue(details?.selectedGolfId);

      let itemsName = selectedItemsValue
        ? golfData?.data?.docs.find((c: any) => c?._id === selectedItemsValue)
        : null;

      let item = itemsName ? itemsName?.name : "Select Golf Club";

      setSelectedCategoryName(item);
    }
  }, [details]);

  useEffect(() => {
    if (selectedStartTime && selectedEndTime) {
      if (selectedEndTime <= selectedStartTime) {
        setValidationValue(false);
        return;
      } else {
        setValidationValue(true);
        return;
      }
    }

    if (selectedStartDate && selectedEndDate) {
      if (selectedEndDate < selectedStartDate) {
        setValidationValue(false);
        return;
      } else {
        setValidationValue(true);
        return;
      }
    }
  }, [selectedStartTime, selectedEndTime, selectedStartDate, selectedEndDate]);

  const getGolf = async (
    query:
      | {
          offset: number | string;
          search: string;
        }
      | undefined
  ) => {
    let response = await getGolfData(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };

  const formik: any = useFormik({
    initialValues: {},
    onSubmit: async () => {
      if (!isValid) {
        return;
      }

      let obj = {
        selectedStartDate: formatDate(selectedStartDate),
        selectedEndDate: formatDate(selectedEndDate),
        selectedStartTimeFormat: selectedStartTime,
        selectedStartTime: selectedStartTimeValue,
        selectedEndTime: selectedEndTimeValue,
        selectedEndTimeFormat: selectedEndTime,
        selectedCategory: categoryIdOnChange,
        selectedPerson: selectedPerson ?? "",
        selectedItemsValue: selectedItemsValue ?? "",
        selectedItemsName : selectedItemsName
      };
      handleClose(true, obj, false);
    },
  });

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={() => handleClose(false, {}, false)}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>Filter</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false, {}, false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div>
            <Row>
              <Col xs={6}>
                <div className="form-group">
                  <label className="form-label fw-bold">Start Date</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      name="startDate"
                      onChange={handleStartDateChange}
                      slotProps={{
                        textField: {
                          size: "small",
                          error: false,
                        },
                      }}
                      value={selectedStartDate}
                      views={["year", "month", "day"]}
                      sx={{
                        "&.MuiFormControl-root": {
                          width: "100%",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </Col>
              <Col xs={6}>
                <div className="form-group">
                  <label className="form-label fw-bold">End Date</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      name="endDate"
                      onChange={handleEndDateChange}
                      slotProps={{
                        textField: {
                          size: "small",
                          error: false,
                        },
                      }}
                      value={selectedEndDate}
                      views={["year", "month", "day"]}
                      sx={{
                        "&.MuiFormControl-root": {
                          width: "100%",
                        },
                      }}
                    />
                    {selectedStartDate && selectedEndDate && (
                      <div style={{ color: "red" }}>
                        {selectedEndDate < selectedStartDate && (
                          <p>End date must be after start date.</p>
                        )}
                      </div>
                    )}
                  </LocalizationProvider>
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: "15px" }}>
              <Col xs={6}>
                <div>
                  <label className="form-label fw-bold">Start Time</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      ampm={true}
                      onChange={handleStartTimeChange}
                      value={selectedStartTime}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-input": { py: 1.35 },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </Col>
              <Col xs={6}>
                <div>
                  <label className="form-label fw-bold">End Time</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      onChange={handleEndTimeChange}
                      value={selectedEndTime}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-input": { py: 1.35 },
                      }}
                    />
                    {selectedStartTime && selectedEndTime && (
                      <div style={{ color: "red" }}>
                        {selectedEndTime <= selectedStartTime && (
                          <p>End time must be after start time.</p>
                        )}
                      </div>
                    )}
                    {/* {formik.touched.endTime && formik.errors.endTime && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.endTime}
                    </div>
                  </div>
                )} */}
                  </LocalizationProvider>
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: "15px" }}>
              <Col xs={6}>
                <div className="form-group">
                  <label
                    className="form-label fw-bold"
                    style={{ marginLeft: 5 }}
                  >
                    Category
                  </label>
                  <select
                    onChange={(e: any) =>
                      handleCategoryChange(e?.target?.value)
                    }
                    value={categoryIdOnChange}
                    className="form-select form-select-solid form-select-lg"
                  >
                    <option value="">Select All</option>
                    {categoryData?.data
                      ? categoryData?.data?.docs?.map((category: any) => (
                          <option value={category?._id} key={category?._id}>
                            {category?.name}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </Col>

              <Col xs={6}>
                <div className="form-group">
                  <label className="form-label fw-bold"> Items </label>
                  <select
                    className="form-select form-select-solid form-select-lg"
                    value={selectedItemsValue}
                    onChange={(e) => {setSelectedItemsValue(e.target.value);
                      setSelectedItemsName(
                      itemsData.find((item: any) => item._id === e.target.value)?.name || ""
                    )
                  }}
                  >
                    <option value="">Items</option>
                    {itemsData && itemsData.length > 0 ? (
                      itemsData.map((items: any) => (
                        <option value={items._id} key={items._id}>
                          {items.name}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No data available
                      </option>
                    )}
                  </select>
                </div>
              </Col>
              <Col xs={6}>
                <div className="form-group">
                  <label className="form-label fw-bold" style={{marginLeft: "8px", marginTop: "18px"}}> Person </label>
                  <select
                    className="form-select form-select-solid form-select-lg"
                    value={selectedPerson}
                    onChange={(e) => setSelectedPerson(e.target.value)}
                  >
                    <option value="">Select Person</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                  </select>
                </div>
              </Col>
            </Row>
          </div>

          <div className="w-100 d-flex align-items-center justify-content-end mt-5">
            <Button
              onClick={() => {
                formik.resetForm();
                handleReset();
                // handleClose(false,{});
              }}
              size="lg"
              variant="secondary"
            >
              Reset
            </Button>
            <div className="w-15px"></div>
            <Button type="submit" size="lg" variant="primary">
              {/* {isApply ? "Loading..." : "Apply"} */}
              Apply
            </Button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  );
};

export default FilterDashboard;
