import clsx from "clsx";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import { requestPassword } from "../../pages/auth/core/_requests";
import axios from "axios";

const initialValues = {
  email: "",
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
});

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();

  const decryptPayload = (ciphertext: any, secretKey: any, iv: any) => {
    const key = CryptoJS.enc.Base64.parse(secretKey);
    const ivParsed = CryptoJS.enc.Base64.parse(iv);
    const decrypted = CryptoJS.AES.decrypt(ciphertext?.encryptedData, key, { iv: ivParsed }).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted);
  };
   // Fetch API and store payload in localStorage
   useEffect(() => {
    const fetchMaintenanceData = async () => {
      // try {
      //   const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/app-maintenance`);
      //   const data = response?.data?.data;
      //   if (data === false) {
      //     // localStorage.setItem('maintenanceData', data);
      //     localStorage.setItem('maintenanceData', "false");
      //   }else{
      //     // const decryptedResponseData = decryptPayload(data, process.env.REACT_APP_SECRET_KEY, process.env.REACT_APP_IV);
      //     // localStorage.setItem('maintenanceData', decryptedResponseData);
      //     localStorage.setItem('maintenanceData', "false");
      //   }
      // } catch (error) {
      //   console.error("Error fetching maintenance data:", error);
      // }
      localStorage.setItem('maintenanceData', "false");
    };

    fetchMaintenanceData();
  }, []);  
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        requestPassword(values.email)
          .then((data: any) => {
            if (data?.data?.status) {
              setNewPassword(data?.data?.data?.password ?? "");
              snackActions.success(data?.data?.message ?? "Success!");
              formik.resetForm();
            } else {
              snackActions.dismissibleError(
                data?.data?.message ?? "Something went wrong!, please try again"
              );
            }
            setLoading(false);
          })
          .catch((error: any) => {
            snackActions.error(
              error.response.data.message ??
                "Something went wrong!, please try again"
            );
            setLoading(false);
            setSubmitting(false);
            setStatus("Something went wrong!, please try again");
          });
      }, 1000);
    },
  });

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_password_reset_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-8">
        <img src="../media/app-logo/logo.svg" alt="App Logo" />
        {/* begin::Title */}
        <h1 className="mb-2 mt-15">Forgot Password ?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="fs-24 m-0">
          Enter your email to reset your password.
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Form group */}
      <div className="fv-row mb-8">
        {/* <label className="form-label fw-bolder text-gray-900 fs-6">Email</label> */}
        <input
          type="email"
          placeholder="Enter your email"
          autoComplete="off"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control py-5",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="d-flex flex-wrap justify-content-between pb-lg-0">
        <p onClick={() => navigate("/auth/login")}>
          Back to <u className="cursor-pointer">login</u>
        </p>
        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-primary me-4"
          disabled={loading}
        >
          {!loading && <span className="indicator-label">Submit</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
    </form>
  );
}
