import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import { KTIcon } from "../../_metronic/helpers";
import { IWaitingInfoDetails } from "../../constants/types";
import { charValidate, convertTo24HourFormat } from "../utility";
import { formatDate } from "../../helpers/commonFunctions";
import { Chip } from "@mui/material";

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  details: IWaitingInfoDetails;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const WaitListInfoDetails = ({ show, handleClose, details }: Props) => {
  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-600px"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>Request Details</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-3">
        <Card style={{ width: "100%", boxShadow: "none", paddingBottom: 0 }}>
          <Card.Body>
            <Card.Title className="pb-4">User Details</Card.Title>
            <Container>
              <Row>
                <Col xs={6} style={{ paddingLeft: 0 }}>
                  <Card.Subtitle className="text-muted">
                    Full Name:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.user?.firstName || details?.user?.lastName
                      ? charValidate(
                        details?.user?.firstName +
                        " " +
                        details?.user?.lastName,
                        20,
                      )
                      : "N/A"}
                  </Card.Text>
                </Col>
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">Email:</Card.Subtitle>
                  <Card.Text>
                    {details?.user?.email
                      ? details?.user?.email
                      : "N/A"}
                  </Card.Text>
                </Col>

                {/* <Col style={{ paddingLeft: 0,paddingTop: 15 }}>
                  <Card.Subtitle className="text-muted">
                    Description:
                  </Card.Subtitle>
                  <Card.Text>
                      {details?.description
                        ? details?.description
                        : "N/A"}
                    </Card.Text>
                </Col> */}
              </Row>
            </Container>
          </Card.Body>
        </Card>
        <Card style={{ width: "100%", boxShadow: "none", paddingTop: 0 }}>
          <Card.Body>
            <Card.Title className="pb-4">WaitList Details</Card.Title>
            <Container>
              <Row>
                <Col xs={6} style={{ paddingLeft: 0 }}>
                  <Card.Subtitle className="text-muted">Category:</Card.Subtitle>
                  <Card.Text>
                    {details?.catId === process.env.REACT_APP_ID_REST_CATE
                      ? 'Restaurant'
                      : details?.catId === process.env.REACT_APP_ID_HOTEL_CATE
                        ? 'Hotel'
                        : details?.catId === process.env.REACT_APP_ID_GOLF_CATE
                          ? 'Golf'
                          : details?.catId === process.env.REACT_APP_ID_HEALTH_CARE_CATE
                            ? 'Healthcare'
                            : details?.catId === process.env.REACT_APP_ID_SALOON_CATE
                              ? 'Wellness'
                              : 'NA'
                    }
                  </Card.Text>

                </Col>
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">
                    Item Name:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.itemId?.name}
                  </Card.Text>
                </Col>
              </Row>

              <Row className="pt-2">
                <Col xs={6} style={{ paddingLeft: 0, paddingTop: 10 }}>
                  <Card.Subtitle className="text-muted">
                    Type:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.itemId.type}
                  </Card.Text>
                </Col>

                <Col xs={6} style={{ paddingLeft: 10, paddingTop: 10 }}>
                  <Card.Subtitle className="text-muted">
                    Date:
                  </Card.Subtitle>
                  <Card.Text>
                    {formatDate(details?.bookingDate, "MM/dd/yy")}
                  </Card.Text>
                </Col>

              </Row>
              <Row className="pt-2">
                <Col xs={6} style={{ paddingLeft: 0, paddingTop: 10 }}>
                  <Card.Subtitle className="text-muted">
                    Earliest Time:
                  </Card.Subtitle>
                  <Card.Text>
                 
                    {details?.earliestTime
                      ? convertTo24HourFormat(details?.earliestTime)
                      : "N/A"}
                  </Card.Text>
                </Col>
                <Col xs={6} style={{ paddingTop: 10 }}>
                  <Card.Subtitle className="text-muted">
                    Latest Time:
                  </Card.Subtitle>
                  <Card.Text>
                      {details?.latestTime
                      ? convertTo24HourFormat(details?.latestTime)
                      : "N/A"}
                  </Card.Text>
                </Col>
              </Row>
              <Row className="pt-2">
                <Col xs={6} style={{ paddingLeft: 0, paddingTop: 10 }}>
                  <Card.Subtitle className="text-muted">
                    Person:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.person}
                  </Card.Text>
                </Col>
                <Col xs={6} style={{ paddingTop: 15 }}>
                  <Card.Subtitle className="text-muted">
                    Description:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.description
                      ? details?.description
                      : "N/A"}
                  </Card.Text>
                </Col>
                {details.reasonText &&
                  <Col xs={6} style={{ paddingTop: 15 }}>
                    <Card.Subtitle className="text-muted">
                      {details.status == '1' || details.status == '2' ? 'Info' : 'Reason'}
                    </Card.Subtitle>
                    <Card.Text>
                      {details.description}
                    </Card.Text>
                  </Col>
                }
              </Row>

            </Container>
          </Card.Body>
        </Card>

        <Card style={{ width: "100%", boxShadow: "none", paddingTop: 0 }}>
          <Card.Body>
            <Card.Title className="pb-4">User Preferences</Card.Title>
            <Container style={{ paddingLeft: 0 }}>
              <label style={{ fontSize: '14px', paddingLeft: 0 }}> Lunch Time:</label>
              <Row style={{ paddingLeft: 0, paddingTop: 10 }}>
                <Col >
                  <Card.Subtitle className="text-muted">
                    Earliest Time:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.preference?.lunchEarliestTime ?? "N/A"}
                  </Card.Text>
                </Col>
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">
                    Latest Time:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.preference?.lunchLatestTime ?? "N/A"}
                  </Card.Text>
                </Col>
              </Row>

              <label style={{ paddingLeft: 0, paddingTop: 10 }}> Dinner Time:</label>
              <Row style={{ paddingLeft: 0, paddingTop: 10 }} >
                <Col >
                  <Card.Subtitle className="text-muted">
                    Earliest Time:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.preference?.dinnerEarliestTime ?? "N/A"}
                  </Card.Text>
                </Col>
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">
                    Latest Time:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.preference?.dinnerLatestTime ?? "N/A"}
                  </Card.Text>
                </Col>
              </Row>

              <Row style={{ paddingLeft: 0, paddingTop: 15 }}>
                <Col xs={6}  >
                  <Card.Subtitle className="text-muted">
                    Spend per Outing(in $):
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.preference?.spendPerOuting ?? "N/A"}
                  </Card.Text>
                </Col>
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">
                    Average Tip:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.preference?.averageTip ?? "N/A"}
                  </Card.Text>
                </Col>

              </Row>
              <Row style={{ paddingLeft: 0, paddingTop: 15 }}>
                <Col xs={6} >
                  <Card.Subtitle className="text-muted">
                    Dishes:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.preference?.dishes?.length ? (
                      details?.preference?.dishes.map((title: any, index: number) => (
                        <Chip key={index} label={title?.name} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} />
                      ))
                    ) : (
                      <Card.Text>N/A</Card.Text>
                    )}
                  </Card.Text>
                </Col>
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">
                    Dinning:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.preference?.dinning?.length ? (
                      details?.preference?.dinning.map((title: any, index: number) => (
                        <Chip key={index} label={title?.name} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} />
                      ))
                    ) : (
                      <Card.Text>N/A</Card.Text>
                    )}
                  </Card.Text>
                </Col>
              </Row>

              <Row style={{ paddingLeft: 0, paddingTop: 15 }}>
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">
                    Wines:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.preference?.wines?.length ? (
                      details?.preference?.wines.map((title: any, index: number) => (
                        <Chip key={index} label={title?.name} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} />
                      ))
                    ) : (
                      <Card.Text>N/A</Card.Text>
                    )}
                  </Card.Text>
                </Col>
                <Col xs={6} style={{ paddingTop: 10 }}>
                  <Card.Subtitle className="text-muted">
                    Music:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.preference?.music?.length ? (
                      details?.preference?.music.map((title: any, index: number) => (
                        <Chip key={index} label={title?.name} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} />
                      ))
                    ) : (
                      <Card.Text>N/A</Card.Text>
                    )}
                  </Card.Text>
                </Col>
              </Row>
              <Row>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </div>
    </Modal>,
    modalsRoot,
  );
};

export default WaitListInfoDetails;
